<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      @hidden="hadleModalAlertClose()"
    >
      <div class="text-center">
        <img
          src="@/assets/images/icon-check.svg"
          alt="success"
          class="mb-3 img"
        />
        <p class="text-text" v-if="text">{{ text }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    hadleModalAlertClose() {
      this.$emit("hadleModalAlertClose");
    },
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}
@media (max-width: 767.98px) {
  /* .text-text {
    font-size: 15px;
  } */
}
</style>
